// swiperjs

// Initializing Swiper
const swiper = new Swiper('.section__staff--block', {
    slidesPerView: 1,
    spaceBetween: 25,
    loop: true,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    speed: 1000,
    breakpoints: {
        100: {
            slidesPerView: 1,
        },
        1024: {
            slidesPerView: 2,
        },
        1200: {
            slidesPerView: 3,
        },
    },
});

const swiper2 = new Swiper('.section__slideshow', {
    slidesPerView: 1,
    loop: true,
    speed: 1000,
    effect: "fade",
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    autoplay: {
        delay: 2000,
        disableOnInteraction: false,
    },
});